<template>
   <div class="w-100 mx-3">
    <SkeletonText class="mt-4 mb-3 m-0"></SkeletonText>
        <div class="row w-100 m-0" v-if="type=='list'">
        <div class="col-md-4 col-12" v-for="index in 3" :key="index">
            <b-card class="card">
                <b-skeleton-img height="250px"></b-skeleton-img>
                <b-skeleton type="input" class="my-3"></b-skeleton>
                <b-skeleton type="button" class="w-100"></b-skeleton>
            </b-card>
        </div>
    </div>
        <b-row class="w-100 m-0" v-else>
  <b-col cols="12">
    <b-card no-body img-left>
      <b-skeleton-img card-img="left" width="400px" height="400px"></b-skeleton-img>
      <b-card-body class="d-flex justify-content-between flex-column">
        <b-skeleton type="input"></b-skeleton>
        <div class="">
            <b-skeleton animation="wave" width="85%"></b-skeleton>
  <b-skeleton animation="wave" width="55%"></b-skeleton>
  <b-skeleton animation="wave" width="70%"></b-skeleton>
        </div>
        <b-skeleton type="button" class="w-100"></b-skeleton>
      </b-card-body>
    </b-card>
  </b-col>
  </b-row>
    </div>
</template>
<script>
// import { component } from 'vue/types/umd';
import SkeletonText from './Text.vue';
export default {
    props:{type:String},
components:{
  SkeletonText
}
}
</script>